import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Stack from "react-bootstrap/Stack";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import "./styles/aboutSideMenu.scss";

export default function FinAidMenu({ type }) {
  const data = useStaticQuery(graphql`
    query finAidSideMenuLinks {
      site {
        siteMetadata {
          finAidMenu {
            link
            name
          }
        }
      }
    }
  `);

  if (type === "bottom") {
    return (
      <div id="admissionsOverviewBottomMenu" className="fin-aid">
        <Row className="p-3 p-md-5">
          <Col>
            <Nav
              defaultActiveKey={data.site.siteMetadata.finAidMenu.link}
              className="flex-column"
            >
              <h2 className="menu-title mb-md-5">Graduate Admissions</h2>
              <Row>
                {data.site.siteMetadata.finAidMenu.map((link) => (
                  <Col md={6}>
                    <Nav.Item>
                      <Stack direction="horizontal" gap={3}>
                        <img
                          src="https://web-cdn.meridianuniversity.edu/site-content-images/icons/menu-cursor.svg"
                          className="img-fluid pe-1"
                          alt="menu cursor"
                        />
                        <Link to={link.link}>
                          <Nav.Link as="span" eventKey={link.link}>
                            {link.name}
                          </Nav.Link>
                        </Link>
                      </Stack>
                    </Nav.Item>
                  </Col>
                ))}
              </Row>
            </Nav>
          </Col>
        </Row>
      </div>
    );
  } else {
    return (
      <div id="finAidSideMenu">
        <Row className="mt-5 p-3 white-bg">
          <Col>
            <Nav
              defaultActiveKey={data.site.siteMetadata.finAidMenu.link}
              className="flex-column"
            >
              <h4 className="menu-title">Graduate Admissions</h4>
              {data.site.siteMetadata.finAidMenu.map((link) => (
                <Nav.Item>
                  <Link to={link.link}>
                    <Nav.Link as="span" eventKey={link.link}>
                      <FontAwesomeIcon
                        icon={faChevronCircleRight}
                        color="#0078ae"
                      />
                      {link.name}
                    </Nav.Link>
                  </Link>
                </Nav.Item>
              ))}
            </Nav>
          </Col>
        </Row>
      </div>
    );
  }
}
